// extracted by mini-css-extract-plugin
export var button = "Ca";
export var header = "Aa";
export var hero = "xa";
export var hero__dark = "ya";
export var hero__inner = "za";
export var hero__secondary = "Ga";
export var hero__third = "Ha";
export var illustration = "Ea";
export var img__wrap = "Fa";
export var info = "Da";
export var sub = "Ba";